<template>
  <div>
    <Toolbar :handle-reset="resetForm" :handle-submit="onSendForm" />
    <PageForm
      :errors="violations"
      :values="item"
      :loading="isLoading"
      refForm="updateForm"
      ref="updateForm"
      :handle-submit="onSendForm"
      :parent="parent"
    />

    <p class="headline">{{ $t("childPages") }}</p>
    <v-btn
      color="primary"
      :to="{ name: 'PageCreate', params: { parent: item['@id'] } }"
    >
      {{ $t("createChildPage") }}
    </v-btn>

    <v-data-table
      :headers="headers"
      :items="pageItems"
      :items-per-page="15"
      :loading="isLoading"
      :loading-text="$t('Loading...')"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isDescending"
      class="elevation-1"
      item-key="@id"
      v-if="item.children && item.children.length"
    >
      <template slot="item.enabled" slot-scope="{ item }">
        <v-icon v-if="item.enabled">mdi-check-circle</v-icon>
        <v-icon v-else>mdi-close-circle</v-icon>
      </template>
      <ActionCell
        :handle-delete="() => deleteHandler(props.item)"
        :handle-edit="() => editHandler(props.item)"
        slot="item.action"
        slot-scope="props"
      ></ActionCell>
    </v-data-table>
    <Loading :visible="isLoading || deleteLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import PageForm from "../../components/page/Form.vue";
import Loading from "../../components/Loading";
import Toolbar from "../../components/Toolbar";
import UpdateMixin from "../../mixins/UpdateMixin";
import CreateMixin from "../../mixins/CreateMixin";
import ActionCell from "../../components/ActionCell";
import PageMixin from "../../mixins/PageMixin";

const servicePrefix = "Page";

export default {
  name: "PageUpdate",
  servicePrefix,
  mixins: [CreateMixin, UpdateMixin, PageMixin],
  data() {
    return {
      updateMessage: this.$t("successfullyUpdated"),
      deleteMessage: this.$t("successfullyDeleted"),
      updateRoute: null,
      selected: [],
      headers: [
        { text: this.$i18n.t("title"), value: "title" },
        { text: this.$i18n.t("enabled"), value: "enabled" },
        {
          text: this.$i18n.t("Actions"),
          value: "action",
          sortable: false,
        },
      ],
      showPageDialogForm: false,
      pageFormItem: {},
      pageDialogTitle: this.$t("AddPage"),
      sortBy: "entryOrder",
      isDescending: false,
    };
  },
  components: {
    Loading,
    Toolbar,
    PageForm,
    ActionCell,
  },
  computed: {
    ...mapFields("page", {
      deleteLoading: "isLoading",
      isLoading: "isLoading",
      error: "error",
      updated: "updated",
      violations: "violations",
      totalItems: "totalItems",
      resetList: "resetList",
    }),
    ...mapFields("page", {
      deleted: "deleted",
    }),
    ...mapGetters("page", ["find"]),
    pageItems() {
      return this.item.children
        .map((child) => this.resolvePage(child))
        .filter((e) => e);
    },
    parent() {
        return this.item['parent'] ? this.resolvePage(this.item['parent']) : {}
    }
  },
  methods: {
    ...mapActions("page", {
      deleteItem: "del",
      retrieve: "load",
      update: "update",
      getPage: "fetchAll",
    }),
    ...mapActions("page", {
      deletePageItem: "del",
    }),
    editHandler(item) {
      this.$router.push({
        name: `${this.$options.servicePrefix}Update`,
        params: { id: item["@id"] },
      });
      this.retrieve(item["@id"]);
    },
    deleteHandler(item) {
      this.deletePageItem(item);
    },
    deleteWatcher(deleted) {
      if (!deleted) {
        return;
      }

      this.item.pageItems.splice(
        this.item.pageItems.indexOf(deleted["@id"]),
        1
      );
      this.showMessage(this.deleteMessage);
    },
    pageDialogControl(close = false) {
      this.showPageDialogForm = !close;
    },
    handleCreated(item) {
      this.item.pageItems.push(item["@id"]);
    },
    handleUpdated() {},
    createOrEditPageHandler(item) {
      this.pageFormItem = item ?? {};
      if (item) {
        this.pageDialogTitle = this.$t("EditPage");
      } else {
        this.pageDialogTitle = this.$t("AddPage");
      }
      this.pageDialogControl();
    },
  },
};
</script>
